import Vue from "vue";
import router from "~/router/index";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import random from "lodash/random";
import Fuse from "fuse.js";

import shares from "../shared";
import _sort from "~/shared/_sortProductGroups";

import * as videosStore from "~/store/plpModules/plp.video.store.js";

export default {
  namespaced: true,
  state: () => ({
    catalog_cfg: window.catalog_cfg,
    settings: window.settings,
    location: window.location,
    options: {
      gender: "",
      size: "",
      slug: "",
      style: "",
      sku: "",
      color: "",
      categories: "",
      catalogs: "",
      query: {},
      hash: ""
    },
    sorting: "",
    searchBarInput: "",
    urlCaptures: {},
    filtersListData: {},
    productSort: {},
    product_groups: [],
    // Shop By Data
    shopByOption: {},
    shopByOptions: [
      {
        label: "Price Low-High",
        value: "priceLowHigh"
      },
      {
        label: "Price High-Low",
        value: "priceHighLow"
      },
      {
        label: "Bestselling",
        value: "bestselling"
      },
      {
        label: "Volume",
        value: "styles",
        layoutTitles: "styles"
      }
    ],
    layoutTitles: {
      materials: [],
      collections: []
    },
    productList: [],
    filterBarOpenAtStart: "",
    slideOut: false,
    overlay: false,
    filters: {
      gender: [],
      colors: [],
      sizes: [],
      materials: [],
      collections: [],
      categories: [],
      features: [],
      styles: [],
      injuries: [],
      arch_height: [],
      catalogs: []
    },
    filtersDisplay: {
      gender: true,
      sizes: true,
      categories: true,
      colors: false,
      materials: false,
      collections: false,
      features: false,
      styles: false,
      injuries: false,
      arch_height: false,
      catalogs: false
    },
    filtersMapping: {
      colors: "generic_color_list",
      styles: "meta_style_list",
      features: "feature_list",
      categories: "type_list",
      materials: "material_list",
      collections: "collection_list",
      injuries: "injury_list",
      arch_height: "arch_height_list"
    }
  }),
  getters: {
    /**
     * Support module
     */
    ...videosStore.getters,
    filtersList(state) {
      return Object.keys(state.filters);
    },
    filteredListTitlesCount(state, getters) {
      let count = 0;
      getters.splitedFootwearColorProductList.forEach((titles) => {
        if (titles.groups) count += titles.groups.length;
      });
      return count;
    },
    isFilterEmpty: (state) => (filterName) => {
      if (state.filters[filterName] && state.filters[filterName].length > 0) {
        return false;
      }
      return true;
    },
    isFiltersListEmpty(state, getters) {
      let empty = true;
      getters.filtersList.forEach((filterName) => {
        if (empty) empty = getters.isFilterEmpty(filterName);
      });
      return empty;
    },
    isShopByActive(state) {
      return !isEmpty(state.shopByOption) && state.shopByOption.value;
    },
    isShopByLayout(state, getters) {
      return getters.isShopByActive && state.shopByOption.layoutTitles;
    },
    /*
      Groups of product after filtering process, this is for both when
      shopBy is active and not active
    */
    groupsOfProducts(state, getters) {
      if (!getters.isShopByLayout) return getters.sortedProductList;
      // Flatten the products groups
      let _productList = [];
      let _seenProduct = {};
      getters.sortedProductList.forEach((collection) => {
        collection.groups.forEach((group) => {
          if (!_seenProduct[`${group.name}${group.gender}`]) _productList.push(group);
        });
      });
      return _productList;
    },
    /*
      This filter looks for all active filters inside every group of products
      after the filtering process
    */
    filtersAvailability(state, getters) {
      const seen = {};
      getters.groupsOfProducts.forEach((group) => {
        Object.values(state.filtersMapping).forEach((filterName) => {
          group[filterName].forEach((option) => {
            if (!seen[filterName] || isEmpty(seen[filterName])) seen[filterName] = {};
            seen[filterName][option.id] = 1;
          });
        });
      });
      Object.keys(seen).forEach((key) => {
        let availableIds = Object.keys(seen[key]).map((n) => Number(n));
        seen[key] = availableIds;
      });
      return seen;
    },
    getState(state, getters) {
      if (getters.isRegionAvailable) return state.sorting.location.state;
      return "";
    },
    getCountry(state, getters) {
      if (getters.isCountryAvailable) return state.sorting.location.country;
      return window.settings.region || "us";
    },
    /*
      Check if customer country is available
    */
    isCountryAvailable(state) {
      return state.sorting && state.sorting.location && state.sorting.location.country;
    },
    /*
      Check if customer region is available
    */
    isRegionAvailable(state) {
      return state.sorting && state.sorting.location && state.sorting.location.state;
    },
    isFootbedsRoute(state) {
      if (state.filters.categories[0]) {
        return state.filters.categories[0].name.toLowerCase() === "footbeds";
      }
    },
    isFootwearRoute(state) {
      if (state.filters.categories[0]) {
        return state.filters.categories[0].name.toLowerCase() === "footwear";
      }
    },
    filteredProductList(state, getters) {
      let _productList = state.productList;

      if (state.searchBarInput) {
        _productList = flt.filterBySearchBar(_productList, state);
      }
      if (state.filters.gender && state.filters.gender.length > 0) {
        _productList = flt.filterByGender(_productList, state);
      }
      if (state.filters.colors && state.filters.colors.length > 0) {
        _productList = flt.filterByColors(_productList, state);
      }
      if (state.filters.materials && state.filters.materials.length > 0) {
        _productList = flt.filterByMaterials(_productList, state);
      }
      if (state.filters.categories && state.filters.categories.length > 0) {
        _productList = flt.filterByCategories(_productList, state);
      }
      if (state.filters.features && state.filters.features.length > 0) {
        _productList = flt.filterByFeatures(_productList, state);
      }
      if (state.filters.collections && state.filters.collections.length > 0) {
        _productList = flt.filterByCollections(_productList, state);
      }
      if (state.filters.styles && state.filters.styles.length > 0) {
        _productList = flt.filterByStyles(_productList, state);
      }
      if (state.filters.injuries && state.filters.injuries.length > 0) {
        _productList = flt.filterByInjuries(_productList, state);
      }
      if (state.filters.arch_height && state.filters.arch_height.length > 0) {
        _productList = flt.filterByArchHeight(_productList, state);
      }
      if (state.filters.sizes && state.filters.sizes.length > 0) {
        _productList = flt.filterBySizes(_productList, state);
      }
      if (state.filters.catalogs && state.filters.catalogs.length > 0) {
        _productList = flt.filterByCatalogs(_productList, state);
      }
      if (state.shopByOption.value === "bestselling") {
        _productList = flt.filterByBestselling(_productList, state);
      }
      if (!state.searchBarInput && state.filters.catalogs && state.filters.catalogs.length >= 0) {
        // Decision to ignore webstoreDeals on shop all pages
        if (!state.filters.catalogs.find((catalog) => catalog.name !== "deals")) {
          _productList = flt.ignorewebstoreDeals(_productList, state);
        }
        if (!state.filters.catalogs.find((catalog) => catalog.name !== "MadeForYou")) {
          _productList = flt.ignorewebstoreMadeForYou(_productList, state);
        }
      }
      return _productList;
    },
    splitedFootwearColorProductList(state, getters) {
      let _productList = getters.filteredProductList;
      // Split up footwear colors into its own product tile
      let seen = {};
      for (let i = 0; i < _productList.length; i++) {
        if (
          _productList[i].product_category == "footwear" &&
          !seen[_productList[i].slug] &&
          _productList[i].color_list &&
          _productList[i].color_list.length >= 2 &&
          _productList.filter(({ slug }) => _productList[i].slug == slug).length == 1
        ) {
          const product = cloneDeep(_productList[i]);
          _productList.splice(i, 1);
          for (let k = 0; k < product.color_list.length; k++) {
            const cloneProduct = cloneDeep(product);
            const colorObj = cloneProduct.color_list[k];
            cloneProduct.color_list = [
              ...cloneProduct.color_list.filter(
                ({ data_color }) => data_color == colorObj.data_color
              ),
              ...cloneProduct.color_list.filter(
                ({ data_color }) => data_color != colorObj.data_color
              )
            ];
            _productList.push(cloneProduct);
          }
          seen[product.slug] = 1;
        }
      }
      return _productList;
    },
    sortedProductList(state, getters) {
      let _productList = getters.splitedFootwearColorProductList;
      if (getters.isShopByActive) {
        if (state.shopByOption.value === "priceHighLow") {
          _productList.sort(_sort.sortByPriceHighToLow);
        } else if (state.shopByOption.value === "priceLowHigh") {
          _productList.sort(_sort.sortByPriceLowToHigh);
        } else if (state.shopByOption.value === "bestselling") {
          _productList.sort((a, b) => {
            return _sort.sortByBestSelling(a, b, {
              country: getters.getCountry,
              state: getters.getState,
              isCountryAvailable: getters.isCountryAvailable,
              isRegionAvailable: getters.isRegionAvailable
            });
          });
        } else {
          _productList = srt.moveProductOnSaleIntoHigherRandomSlot(_productList, state);
        }
        if (state.shopByOption.layoutTitles) {
          _productList = srt.sortByTitles(_productList, state);
        }
      } else {
        _productList = srt.sortCatalogRandomly(_productList, "webstoreCurrent");
        _productList = srt.sortCatalogRandomly(_productList, "webstoreClearance");
        _productList = srt.sortBySeason(_productList, state);
        _productList = srt.sortByStockStatus(_productList, state);
        _productList = srt.sendClearanceToBottomOfList(_productList, state);
        _productList = srt.sortByProductSort(_productList, state, getters);
      }
      return _productList;
    }
  },
  actions: {
    setState({ commit }, payload) {
      commit("updateState", payload);
    },
    saveState({ state }) {
      localStorage?.setItem("plp", JSON.stringify(state));
    },
    loadState({ commit }) {
      if (localStorage?.getItem("plp")) {
        let parsedData = JSON.parse(localStorage?.getItem("plp"));
        if (parsedData) {
          JSON.parse(localStorage?.getItem("plp"))
        }
      }
    },
    // Applied Filters
    setFiltersAndUpdateRoute({ getters, dispatch }, payload) {
      dispatch("setFilters", payload);
      if (
        payload.categories &&
        payload.categories.find((el) => el.id != 530) &&
        getters.isShopByActive
      ) {
        dispatch("clearShopBy");
      }
      dispatch("clearShopBy");
      dispatch("setupRoute");
    },
    clearFiltersAndUpdateRoute({ dispatch }) {
      dispatch("clearFilters");
      dispatch("setupRoute");
    },
    clearSpecificFiltersAndUpdateRoute({ dispatch }, payload) {
      dispatch("clearSearchBarInput");
      payload.forEach((filter) => {
        const filterObject = {};
        filterObject[filter] = [];
        dispatch("setFilters", filterObject);
      });
      dispatch("setupRoute");
    },
    setFilters({ state, commit, dispatch }, payload) {
      commit("updateFilters", payload);
    },
    clearFilters({ dispatch }) {
      dispatch("clearSearchBarInput");
      dispatch("setFilters", {
        gender: [],
        colors: [],
        sizes: [],
        materials: [],
        collections: [],
        categories: [],
        features: [],
        styles: [],
        injuries: [],
        arch_height: [],
        catalogs: []
      });
    },
    setSearchBarInput({ dispatch, commit }, payload) {
      if (isEmpty(payload)) dispatch("clearSearchBarInput");
      let cleanPayload = payload.replace(/[^\w\s]/gi, " ").trim();
      dispatch("clearShopBy");
      commit("updateSearchBarInput", cleanPayload);
    },
    clearSearchBarInput({ commit }) {
      commit("updateSearchBarInput", "");
    },
    clearShopBy({ commit }) {
      commit("updateShopByOption", {});
    },
    setupRoute({ state }, payload) {
      let params = {};
      let query = {};
      let hash = "";

      let paramsVariables = ["gender", "categories", "catalogs"];
      let queriesVariables = [
        "sizes",
        "colors",
        "materials",
        "features",
        "collections",
        "styles",
        "injuries",
        "arch_height"
      ];

      Object.keys(state.filters).forEach((el) => {
        let data = state.filters[el];
        if (data.length == 0) return;

        if (paramsVariables.includes(el)) {
          if (el === "gender") params[el] = data[0].name;
          if (el === "categories" && data.length === 1) params[el] = data[0].name.toLowerCase();
          if (el === "catalogs" && data.length === 1)
            params[el] = data.map((x) => x.match_string)[0];
        } else if (queriesVariables.includes(el)) {
          if (el === "sizes") query[el] = data.map((x) => x.mens).join(",");
          if (el === "colors") query[el] = data.map((x) => x.name.toLowerCase()).join(",");
          if (
            ["materials", "features", "collections", "styles", "injuries", "arch_height"].includes(
              el
            )
          )
            query[el] = data.map((x) => x.id).join(",");
        }
      });

      Object.keys(router.currentRoute.query).forEach((el) => {
        if (!queriesVariables.includes(el)) query[el] = router.currentRoute.query[el];
      });

      /* setupShopByfromQuery */
      if (state.shopByOption && state.shopByOption.value)
        query["shopby"] = state.shopByOption.value;
      /* setupSearchBarfromQuery */
      if (state.searchBarInput && !isEmpty(state.searchBarInput)) query["q"] = state.searchBarInput;

      let result = { query };

      // Check for undefined params, since vue router is looking at undefined params also
      Object.keys(router.currentRoute.params).forEach((key) =>
        params[key] === undefined ? (params[key] = undefined) : {}
      );

      if (router.currentRoute.hash) hash = router.currentRoute.hash;

      if (
        !isEqual(params, router.currentRoute.params) ||
        !isEqual(query, router.currentRoute.query) ||
        !isEqual(hash, router.currentRoute.hash)
      ) {
        result["params"] = params;
        result["query"] = query;
        result["hash"] = hash;
        if (payload && payload.route && (payload.route === "push" || payload.route === "replace")) {
          if (payload.route === "push") router.push(result);
          if (payload.route === "replace") router.replace(result);
        } else {
          router.push(result);
        }
      }
    },
    // Hide Filter
    toggleSlideOut({ state }) {
      if (state.slideOut) state.slideOut.toggle();
    },
    setFilterBarOpenAtStart({ commit }, payload) {
      commit("updateFilterBarOpenAtStart", payload);
    },
    // Shop By
    setShopByOption({ dispatch, commit }, payload) {
      commit("updateShopByOption", payload);
      dispatch("setupRoute");
    },
    /**
     * Options contains all route config
     */
    setOptions({ state, commit }, payload) {
      commit("updateOptions", payload);
    }
  },
  mutations: {
    mergeState(state, payload) {
      state = merge(state, payload);
    },
    updateState(state, payload) {
      Object.keys(payload).forEach((key) => {
        Vue.set(state, key, payload[key]);
      });
    },
    updateFilters(state, payload) {
      if (payload) Vue.set(state, "filters", payload);
    },
    updateShopByOption(state, payload) {
      if (payload) Vue.set(state, "shopByOption", payload);
    },
    updateFilterBarOpenAtStart(state, payload) {
      state.filterBarOpenAtStart = payload;
    },
    updateFiltersDisplay(state, payload) {
      if (payload) Vue.set(state, "filtersDisplay", payload);
    },
    updateSearchBarInput(state, payload) {
      Vue.set(state, "searchBarInput", payload ? payload : "");
    },
    // Slide Out
    updateSlideOut(state, payload) {
      if (payload) Vue.set(state, "slideOut", payload);
    },
    updateSlideOutMobile(state, payload) {
      if (payload) Vue.set(state, "slideOutMobile", payload);
    },
    updateOverlay(state, payload) {
      Vue.set(state, "overlay", payload);
    },
    /**
     * Options contains all route config
     */
    updateOptions(state, payload) {
      Vue.set(state, "options", payload);
    }
  }
};

const flt = {
  /*
    Filter productList using getters
  */
  filterByBestselling(products, state) {
    return products.filter((group) => {
      return group.catalog_tags.find((catalog) => catalog === "webstoreCurrent");
    });
  },
  filterByGender(products, state) {
    const _products = products.filter((group) => {
      let found = false;
      state.filters.gender.forEach((genderSelected) => {
        if (!group.gender || group.gender === genderSelected.name) found = genderSelected.name;
      });
      return found;
    });
    return _products.sort((a, b) => {
      if (!a.gender && !b.gender) return 0;
      if (a.gender && !b.gender) return -1;
      if (b.gender && !a.gender) return 1;
      return a.gender - b.gender;
    });
  },
  filterByColors(products, state) {
    const _products = products.filter((group) => {
      const genColorAvailable = group.color_list.find(
        (color) => color.generic_colors && color.generic_colors.length > 0
      );
      if (
        state.filters.colors &&
        state.filters.colors.length > 0 &&
        group.color_list &&
        group.color_list.length > 0 &&
        genColorAvailable
      ) {
        return !isEmpty(getAvailableSizeListWithColor(group, state.filters.colors));
      }
      return true;
    });
    return filterById(_products, "colors", state, "-");
  },
  filterBySizes(products, state) {
    return products.filter((group) => {
      let found = false;
      for (let i = 0; i < state.filters.sizes.length && !found; i++) {
        let sizeSelected = state.filters.sizes[i];
        const available_size_list = getAvailableSizeList(group, state);
        found = available_size_list.find((group_size) => {
          let gender = group.gender || "mens";
          if (group_size[gender] && group_size[gender].indexOf(",") >= 0) {
            return group_size[gender].split(",").find((groupSizeNumber) => {
              return shares.matchHalfSizeToFullSize(sizeSelected[gender], groupSizeNumber);
            });
          }
          return shares.matchHalfSizeToFullSize(
            sizeSelected[gender],
            group_size[gender] || group_size.number
          );
        });
      }
      return found;
    });
  },
  filterByMaterials(products, state) {
    return filterById(products, "materials", state, "-");
  },
  filterByCategories(products, state) {
    return filterById(products, "categories", state, "+");
  },
  filterByFeatures(products, state) {
    return filterById(products, "features", state, "-");
  },
  filterByCollections(products, state) {
    return filterById(products, "collections", state, "-");
  },
  filterByStyles(products, state) {
    return filterById(products, "styles", state, "-");
  },
  filterByInjuries(products, state) {
    return filterById(products, "injuries", state, "-");
  },
  filterByArchHeight(products, state) {
    return filterById(products, "arch_height", state, "-");
  },
  filterBySearchBar(products, state) {
    const options = {
      keys: [
        {
          name: "name",
          weight: 0.3
        },
        {
          name: "product_category",
          weight: 0.7
        },
        "slug",
        "sku_list.upc",
        "sku_list.sku",
        "generic_color_list.name",
        "material_list.name",
        "injury_list.name",
        "meta_style_list.name",
        "collection_list.name",
      ],
      threshold: 0,
      location: 0,
      ignoreLocation: false,
      findAllMatches: true,
    };
    let searches = state.searchBarInput.split(" ");
    searches.forEach((term) => {
      const fuse = new Fuse(products, options);
      products = fuse.search(term).map((found) => found.item);
    });
    return products;
  },
  filterByCatalogs(products, state) {
    if (!state.filters || !state.filters.catalogs) return;
    const filtersSelected = state.filters.catalogs;
    return products.filter((group) => {
      let found = false;
      for (let i = 0; i < filtersSelected.length && !found; i++) {
        filtersSelected[i].tag.split(" ").forEach((webstore) => {
          if (group.catalog_tags.includes(webstore)) found = 1;
        });
      }
      return found;
    });
  },
  ignorewebstoreDeals(products) {
    return products.filter((group) => !group.catalog_tags.includes("webstoreDeals"));
  },
  ignorewebstoreMadeForYou(products) {
    return products.filter((group) => !group.catalog_tags.includes("webstoreMadeForYou"));
  },
  ignoreMetPad(products) {
    const metPadId = 104;
    return products.filter((group) => {
      return !group.feature_list.find(
        (group_feature_list) => Number(group_feature_list.id) === metPadId
      );
    });
  }
};

const srt = {
  sortByTitles(products, state) {
    let titles = state.shopByOption.layoutTitles;
    let titlesList = state.layoutTitles[titles];
    let list_name_map = {
      materials: "material_list",
      styles: "meta_style_list",
      collections: "collection_list"
    };
    let sortProducts = [];
    titlesList.forEach((criteria) => {
      let foundGroups = {
        criteria,
        groups: []
      };
      products.forEach((group) => {
        if (
          group[list_name_map[titles]].find(
            (groupCriteria) => Number(criteria.id) === Number(groupCriteria.id)
          )
        ) {
          foundGroups.groups.push(group);
        }
      });
      if (foundGroups.groups && foundGroups.groups.length > 0) sortProducts.push(foundGroups);
    });
    return sortProducts;
  },
  sortByProductSort(products, state, getters) {
    return _sort.sortByProductSort(products, state.productSort);
  },
  /*
    Sort part of productList randomly, expects productList already sorted by catalog.
    * @param {object} state
    * @param {String} payload
    * @return {void} Do not return anything
  */
  sortCatalogRandomly(products, payload) {
    let catalog = payload;
    let catalogSort = {};

    const CATALOG_SORT = `${catalog}_random_sort`;
    const CATALOG_SORT_TIMESTAMP = `${catalog}_timestamp`;

    let firstMatchIndex = products.findIndex((group) => group.catalog_tags.includes(catalog));
    let lastMatchIndex = shares.findLastIndex(products, (group) =>
      group.catalog_tags.includes(catalog)
    );

    if (localStorage?.getItem(CATALOG_SORT) && sessionStorage?.getItem(CATALOG_SORT_TIMESTAMP)) {
      const catalog_random_sort = JSON.parse(localStorage.getItem(CATALOG_SORT));
      shares.partialSort(products, firstMatchIndex, lastMatchIndex, (a, b) => {
        return Number(catalog_random_sort[a.slug]) - Number(catalog_random_sort[b.slug]);
      });
    } else {
      if (firstMatchIndex >= 0 && lastMatchIndex >= 0) {
        shares.partialShuffle(products, firstMatchIndex, lastMatchIndex, shares.shuffleArray);
        products.forEach((group, index) => (catalogSort[group.slug] = index));
        localStorage?.setItem(CATALOG_SORT, JSON.stringify(catalogSort));
        sessionStorage?.setItem(CATALOG_SORT_TIMESTAMP, Date.now().toString());
      }
    }
    return products;
  },
  /*
    Sort productList by season
    * @param {object} state
    * @return {void} Do not return anything
  */
  sortBySeason(products, state) {
    if (!state.catalog_cfg || !state.catalog_cfg.season_sort) return products;
    const seasonRanking = state.catalog_cfg.season_sort;
    let seasonGroups = products.sort((a, b) => {
      const aRank = seasonRanking[a.season] ? seasonRanking[a.season] : 0;
      const bRank = seasonRanking[b.season] ? seasonRanking[b.season] : 0;
      return bRank - aRank;
    });
    if (seasonGroups.length === products.length) {
      products = seasonGroups;
    } else {
      let count = 0;
      for (let i = 0; i < products.length; i++) {
        const group = products[i];
        if (group.season) {
          products[i] = seasonGroups[count];
          count++;
        }
      }
    }
    return seasonGroups;
  },
  /**
   * Push product that are out of stock/limited sizes to the bottom of the page
   * @param {Object} state
   */
  sortByStockStatus(products, state) {
    products.sort((a, b) => {
      if (a.available_size_list.length === 0) return 1;
      if (b.available_size_list.length === 0) return -1;
      if (shares.getLimitedSizeStatus(a)) return 1;
      if (shares.getLimitedSizeStatus(b)) return -1;
      return 0;
    });
    return products;
  },
  /**
   * Pull one of the product in webstoreSale and webstoreCurrent and put it to slot 1 - 4
   * @param {object} state
   */
  moveProductOnSaleIntoHigherRandomSlot(products, state) {
    const productsOnSale = products.filter((group) => {
      return (
        group.catalog_tags.includes("webstoreSale") &&
        group.catalog_tags.includes("webstoreHomepageBestSeller")
      );
    });
    if (!isEmpty(productsOnSale)) {
      const randomProduct = productsOnSale[random(productsOnSale.length - 1)];
      let randomProductIndex = products.findIndex((product) => randomProduct.slug === product.slug);
      products.splice(randomProductIndex, 1);
      products.splice(random(3), 0, randomProduct);
    }
    return products;
  },
  sendClearanceToBottomOfList(products, state) {
    return products
      .filter((group) => !group.catalog_tags.includes("webstoreClearance"))
      .concat(
        products.filter((group) => group.catalog_tags.includes("webstoreClearance"))
      );
  }
};

function filterById(products, filterName, state, prefix) {
  if (!state.filters || !state.filters[filterName]) return;
  const filtersSelected = state.filters[filterName];
  return products.filter((group) => {
    if (prefix == "+") {
      return includingFilter(filtersSelected, group[state.filtersMapping[filterName]]);
    } else {
      return excludingFilter(filtersSelected, group[state.filtersMapping[filterName]]);
    }
  });
}

function excludingFilter(filtersSelected, taggedFilters) {
  const taggedArr = taggedFilters.map((id_list) => Number(id_list.id));
  const selectedArr = filtersSelected.map((filterSelected) => Number(filterSelected.id));
  return selectedArr.every((filter) => taggedArr.includes(filter));
}

function includingFilter(filtersSelected, taggedFilters) {
  let found = false;
  for (let i = 0; i < filtersSelected.length && !found; i++) {
    let filterSelected = filtersSelected[i];
    found = taggedFilters.find((id_list) => Number(id_list.id) === Number(filterSelected.id));
  }
  return found;
}

function getAvailableSizeListWithColor(group, colors) {
  const colorSizes = [];
  colors.forEach((colorSelected) => {
    const foundColor = group.color_list.find((colorObj) =>
      colorObj.generic_colors.find((generic_color) => {
        return generic_color.name.toLowerCase() === colorSelected.name.toLowerCase();
      })
    );
    if (foundColor && foundColor.available_size_list && foundColor.available_size_list.length > 0)
      colorSizes.push(...foundColor.available_size_list);
  });
  return colorSizes;
}

function getAvailableSizeList(group, state) {
  let available_size_list = group.available_size_list;
  const genColorAvailable = group.color_list.find(
    (color) => color.generic_colors && color.generic_colors.length > 0
  );
  if (
    state.filters.colors &&
    state.filters.colors.length > 0 &&
    group.color_list &&
    group.color_list.length > 0 &&
    genColorAvailable
  ) {
    available_size_list = getAvailableSizeListWithColor(group, state.filters.colors);
  }
  return available_size_list;
}
